<style src="../css/head.css"></style>

<style src="../css/detail.css"></style>
<template>
  <div>
  <Head-section></Head-section>
  <div class="container">
    <div class="text-center  mt-5">
      <a href="#" @click="toHome()">
        <img src="../assets/logo.png" class="img-fluid" alt="Responsive image">
      </a>
    </div>
    <h1 class="text-center text-muted">{{$t("message.Retrieve_Password")}}</h1>

    <b-card border-variant="light">
      <b-form>
        <b-form-group :label="$t('message.Password')" label-for="input-2">
          <b-form-input
            id="input-2"
            type="password"
            v-model="psw_r"
            required
            :placeholder="$t('message.Please_Enter_Passward')"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('message.Password')" label-for="input-3">
          <b-form-input
            id="input-3"
            type="password"
            v-model="psw_c"
            required
            :placeholder="$t('message.Please_Enter_Passward_Again')"
          ></b-form-input>
        </b-form-group>
            <small
          class="form-text text-danger"
          v-if="psw_c!=psw_r"
        >{{$t('message.Psw_Same')}}</small>
        <div class="text-center">
          <b-button
            type="button"
            variant="outline-dark"
            @click="resetPsw(psw_c)"
          >{{$t("message.Submit")}}</b-button>
        </div>
        <small
          class="form-text text-danger"
          :class="{'d-none':rsl}"
          v-if="lan=='cn'"
        >{{ foget_msg}}</small>
        <small
          class="form-text text-danger"
          :class="{'d-none':rsl}"
          v-if="lan=='en'"
        >{{ foget_msg_en}}</small>
      </b-form>
    </b-card>
  </div>
   <Foot-section></Foot-section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HeadSection from "./HeadSection.vue";
import FootSection from "./FootSection.vue";
export default {
  name: "ForgetPswReset",
  components: {
    HeadSection,
    FootSection
  },
   data() {
    return {
      rsl: true,
      psw_r:'',
      psw_c:'',
      uuid:'',

    };
  },
  computed: {
    ...mapGetters(["lan"])
  },
  created(){
    this.uuid = this.$route.query.uuid;
  },
  methods: {
    ...mapActions({
      resetPsw(dispatch,psw) {
        let puuid=this.uuid
        dispatch("resetPsw", { puuid,psw }).then(data => {
          if(this.psw_r!=this.psw_c){
            return
          }
          this.rsl = data.result;
          this.foget_msg_en = data.message_en;
          this.foget_msg = data.message;
          if (data.result) {
            this.toHome()
          }
        });
      }
    }),
    toHome() {
      this.$router.push({
        name: "enhome"
      });
    }
  }
};
</script>

